<template>
<div class="exchange">
  <div class="success" v-show="successShow" @click="successShow = false">
     <div class="successMain" @click.stop="">
        <img src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/exchangeSuccessBlue.png"/>
        <b>恭喜您，兑换成功</b>
        <p>{{message}}</p>
        <button @click="toPage"><img src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/exchangeStartBlue.png"/></button>
     </div>
  </div>
  <div class="title">课程兑换</div>
  <div class="input">
      <input v-model="code" placeholder="请输入课程兑换码"/>
      <button class="btn" :class="{red:code}" @click="submit">立即兑换</button>
  </div>
  <div class="rule">
         <div class="ruleTitle">
           兑换规则
         </div>
         <div class="ruleMain">
            <div class="cont">
            <span>1.</span>
            <p>兑换码是由高研院/研修社通过特定渠道后进行兑换的一种形式。</p>
          </div>
          <div class="cont">
            <span>2.</span>
            <p>兑换成功后，对应课程信息会同步到您的个人账户中。</p>
          </div>
          <div class="cont">
            <span>3.</span>
            每个兑换码可兑换一次，不可重复使用。
          </div>
          <!-- <div class="cont">
            <span>4.</span>
            <p>如有问题请咨询婴幼高研院官方客服：13811674685（微信同步）。</p>
          </div> -->
         </div>
      </div>
</div>
</template>

<script>
  import { Toast } from 'vant';
export default {
  name: "exchange",
  data() {
    return {
      successShow: false,
      code:'',
      message:"",
      title:'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/exchangeTitle.png'
    }
  },
  components:{
  },
  mounted() {
   
  },
  destroyed() {},
  computed: {
    plat() {
      return this.$store.state.plat_id
    }
  },
  methods: {
    submit(){
      if(!this.code) return Toast('请输入课程兑换码')
      this.$request.exchangeLesson({code:this.code}).then(res=>{
          console.log(res)
          this.message = res.message
          this.successShow = true
        
      })
    },
    scanResult(text){
      
        let info = text.split('?')[1].split('&')
        info.forEach(item=>{
          if(item.split('=')[0]=='code') this.code = item.split('=')[1]
        })
      
    },
    toPage() {
      this.$router.push('./purchased2')
    },
  },
  beforeRouteUpdate(to, from, next) {
    console.log('beforeRouteUpdate')
    // console.log(to)
    next()
  },

};
</script>
<style lang="scss">
  body{
    background: #fff;
  }
</style>
<style lang="scss" scoped>
.exchange {
  
  .success{
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top:0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0,0.6);
    backdrop-filter: blur(6px);
    .successMain{
      width: 560px;
      height: 600px;
      background: #FFFFFF;
      border-radius: 32px;
      display: flex;
      flex-direction: column;
      align-items: center;
      >img{
        width: 164px;
        margin-top: 75px;
        margin-bottom: 41px;
      }
      b{
        font-size: 32px;
        color: #1E2531;
      }
      p{
        color: #666666;
        font-size: 28px;
        padding-top: 15px;
        padding-bottom: 50px;
      }
      button {
        background: none;
        border: 0;
        padding: 0;
        cursor: pointer;
        img{
          width: 250px;
        }
      }
    }
  }
  .title{
    font-size: 24px;
    color: #242424;
    font-weight: bold;
    border-bottom: 1px dashed #f0f0f0;
    padding-top: 30px;
    padding-bottom: 20px;
    width: 100%;
    margin-bottom: 30px;
    font-family: SourceHanSans;
  }
  .input{
      width: 100%;
      height: 154px;
      background: #F9F9F9;
      border-radius: 6px;
      line-height: 100px;
      margin-bottom: 40px;
      display: flex;
      box-sizing: border-box;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      text-align: center;
      padding: 30px 0;
      input{
        width: 500px;
        height: 40px;
        line-height: 40px;
        border-radius: 10px;
        font-size: 14px;
        color: #666666;
        border: 1px solid #dadada;
        text-align: center;
        background: #fff;
      }
    }
    .btn{
      cursor: pointer;
      width: 200px;
      height: 34px;
      border-radius: 17px;
      border: 0;
      font-size: 14px;
      color: #BEBEBE;
      line-height: 34px;
      background: #E2E2E2;
    }
    .red{
      background: #0080d6;
      color: #FFFFFF;
    }
    .rule{
      padding-top: 40px;
      .ruleTitle{
        font-size: 16px;
        color: #242424;
        margin-bottom: 20px;
        font-family: SourceHanSans;
        img{
          width: 11px;
          height: 44px;
          margin-right: 8px;
          margin-left: 14px;
        }
      }
      .ruleMain{
        padding: 20px;
        border-radius: 8px;
        background: #f9f9f9;
      }
      .cont{
        color: #666666;
        font-size: 14px;
        line-height: 32px;
        display: flex;
        padding-left: 13px;
        a{
          color: #666666;
        }
      }
    }

}
</style>

